@charset "utf-8";

/* DEFAULTS
$base-font-size: 16px;
$min-font-size: 16px;
$max-font-size: 18px;
*/

$min-font-size: 18px;
$max-font-size: 20px;

@import "so-simple";

.embed-container {
  margin-bottom: 1em;
}

article p {
  margin-bottom: 1rem;
  text-align: justify;
}

.page-content {
  text-align: justify;
}

.e-content a {
  font-weight: bold;
}

.e-content a:hover {
  background-color: lightsalmon;
}

.intro-header {
  margin-bottom: 2rem;
}

.emoji {
  vertical-align: sub;
}

.masthead {
  margin-bottom: 2em;
}

.entry-title, .page-title {
  font-style: normal;
  text-align: left;
}

h2, h3, h4, h5, h6 {
    margin-bottom: 1rem;
}

.entry-meta .entry-date + .entry-category::before {
    content: "\2022";
    padding: 0 0.5em;
    font-family: "Lora", serif;
    font-style: italic;
    white-space: nowrap;
}

form.wj-contact {
  text-align: left;
}

form.wj-contact input, form.wj-contact textarea {
    width: 100%;
    vertical-align: middle;
    margin-top: 0.25em;
    margin-bottom: 0.5em;
    padding: 0.75em;
    font-weight: lighter;
    border-style: solid;
    border-color: #444;
    outline-color: #2e83e6;
    border-width: 1px;
    border-radius: 3px;
    transition: box-shadow .2s ease;
}

form.wj-contact label {
    font-weight: lighter;
}

form.wj-contact label.heading {
    font-weight: bolder;
}

form.wj-contact input[type="checkbox"] {
  width: auto;
  height: 1em;
  width: 1em;
}

form.wj-contact input[type="submit"] {
    outline: none;
    color: white;
    background-color: #2e83e6;
    border-radius: 3px;
    padding: 0.5em;
    margin: 0.25em 0 1.5em 0;
    border: 1px solid transparent;
    height: auto;
}

.entries-grid .entry-title {
  @include fluid-type($min-vw, $max-vw, 24px, 26px);
}
